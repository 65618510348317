import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
<path d="M2187 3474 c-4 -4 -7 -200 -7 -436 0 -339 3 -429 13 -426 9 4 13 100
15 428 1 233 -2 428 -6 432 -5 5 -11 5 -15 2z"/>
<path d="M2244 3449 c-10 -16 5 -25 71 -39 229 -48 430 -219 518 -440 39 -99
50 -154 51 -274 1 -76 5 -111 13 -113 20 -7 26 42 20 156 -9 184 -77 345 -199
476 -109 117 -225 186 -379 225 -91 23 -86 23 -95 9z"/>
<path d="M2246 3262 c-9 -14 14 -29 59 -36 72 -13 179 -72 242 -134 101 -99
153 -227 153 -374 0 -60 3 -78 14 -78 28 0 33 90 11 195 -30 141 -122 279
-235 350 -86 55 -230 100 -244 77z"/>
<path d="M2250 3066 c0 -8 18 -18 43 -24 127 -33 215 -142 223 -277 2 -47 6
-61 20 -63 15 -4 16 4 12 70 -6 86 -31 149 -83 206 -62 69 -215 131 -215 88z"/>
<path d="M2262 2867 c-43 -46 -17 -107 45 -107 57 0 84 69 43 110 -27 27 -62
25 -88 -3z"/>
<path d="M1410 2365 l0 -135 30 0 30 0 0 135 0 135 -30 0 -30 0 0 -135z"/>
<path d="M2302 2368 l3 -133 90 0 90 0 3 43 c3 41 2 42 -27 42 -28 0 -31 -3
-31 -30 0 -28 -3 -30 -35 -30 l-35 0 0 105 0 105 35 0 c32 0 35 -2 35 -30 0
-27 3 -30 31 -30 29 0 30 1 27 43 l-3 42 -93 3 -93 3 3 -133z"/>
<path d="M3500 2430 c0 -11 -7 -20 -15 -20 -8 0 -15 -9 -15 -20 0 -11 7 -20
15 -20 12 0 15 -14 15 -70 l0 -70 45 0 c33 0 45 4 45 15 0 8 -9 15 -20 15 -18
0 -20 7 -20 55 0 48 2 55 20 55 13 0 20 7 20 20 0 13 -7 20 -20 20 -13 0 -20
7 -20 20 0 15 -7 20 -25 20 -18 0 -25 -5 -25 -20z"/>
<path d="M3203 2401 c-38 -24 -53 -49 -53 -89 0 -68 76 -110 140 -77 34 17 64
73 54 100 -9 22 -30 2 -38 -35 -11 -59 -92 -62 -118 -4 -16 35 6 73 50 88 19
6 35 16 35 21 3 18 -37 16 -70 -4z"/>
<path d="M3324 2410 c-32 -13 -64 -59 -64 -91 0 -41 12 -36 35 14 17 36 25 42
53 45 68 7 98 -66 46 -107 -15 -12 -32 -21 -39 -21 -7 0 -18 -7 -25 -15 -11
-13 -8 -15 14 -15 35 0 83 25 96 49 5 11 10 34 10 51 0 42 -15 67 -50 85 -32
17 -45 18 -76 5z"/>
<path d="M2546 2397 c-14 -10 -16 -25 -14 -87 l3 -75 83 -3 82 -3 0 85 c0 67
-3 85 -16 90 -28 11 -120 6 -138 -7z m94 -82 c0 -52 -1 -55 -25 -55 -24 0 -25
3 -25 55 0 52 1 55 25 55 24 0 25 -3 25 -55z"/>
<path d="M2740 2319 l0 -89 30 0 30 0 0 70 c0 68 1 70 25 70 24 0 25 -2 25
-70 l0 -70 30 0 30 0 0 85 c0 47 -3 86 -7 86 -5 1 -43 3 -85 4 l-78 3 0 -89z"/>
<path d="M2955 2402 c-3 -3 -5 -43 -5 -89 l0 -83 30 0 30 0 0 70 c0 68 1 70
25 70 24 0 25 -2 25 -70 l0 -70 30 0 30 0 0 85 c0 47 -3 86 -7 86 -30 5 -153
6 -158 1z"/>
<path d="M1520 2364 c0 -33 3 -36 50 -56 53 -22 67 -48 25 -48 -16 0 -25 6
-25 15 0 9 -9 15 -25 15 -21 0 -25 -5 -25 -30 l0 -31 78 3 77 3 3 33 c3 31 0
34 -52 57 -59 26 -75 55 -31 55 16 0 25 -6 25 -15 0 -10 10 -15 29 -15 25 0
28 3 23 25 -4 24 -7 25 -78 25 l-74 0 0 -36z"/>
<path d="M1720 2364 c0 -33 3 -36 50 -56 53 -22 67 -48 25 -48 -16 0 -25 6
-25 15 0 9 -9 15 -25 15 -21 0 -25 -5 -25 -30 l0 -30 75 0 75 0 0 39 c0 37 -2
39 -50 59 -54 23 -68 52 -25 52 16 0 25 -6 25 -15 0 -9 9 -15 25 -15 20 0 25
5 25 25 0 24 -2 25 -75 25 l-75 0 0 -36z"/>
<path d="M1912 2388 c3 -7 21 -14 41 -16 l37 -3 -40 -59 c-22 -32 -40 -64 -40
-69 0 -7 29 -11 75 -11 60 0 75 3 75 15 0 11 -11 15 -40 15 -22 0 -40 3 -40 6
0 3 18 31 40 63 22 31 40 60 40 64 0 4 -34 7 -76 7 -56 0 -75 -3 -72 -12z"/>
<path d="M2090 2393 c0 -5 13 -43 29 -86 28 -73 29 -81 15 -113 l-14 -34 28 0
c26 0 30 6 66 108 22 59 41 113 44 120 3 10 -3 13 -24 10 -25 -3 -30 -10 -44
-57 l-15 -54 -18 56 c-15 49 -22 57 -43 57 -13 0 -24 -3 -24 -7z"/>
<path d="M2665 2101 c-3 -11 -12 -18 -19 -15 -17 6 -39 -20 -34 -42 5 -26 24
-27 23 -2 0 13 5 23 10 23 6 0 9 -11 7 -25 -6 -43 15 -9 23 38 7 43 1 57 -10
23z"/>
<path d="M2807 2113 c-10 -19 -17 -82 -9 -87 5 -3 12 16 15 42 5 47 3 61 -6
45z"/>
<path d="M1866 2093 c-3 -4 -6 -21 -6 -40 0 -24 4 -33 17 -33 13 0 14 3 5 12
-16 16 -15 23 3 41 12 13 12 16 1 20 -8 3 -17 3 -20 0z"/>
<path d="M2506 2093 c-3 -4 -6 -21 -6 -40 0 -24 4 -33 17 -33 13 0 14 3 5 12
-16 16 -15 23 3 41 12 13 12 16 1 20 -8 3 -17 3 -20 0z"/>
<path d="M1804 2079 c-3 -6 -1 -16 5 -22 16 -16 13 -27 -6 -20 -11 4 -14 3 -9
-5 4 -7 13 -12 21 -12 22 0 29 22 12 35 -21 15 -22 29 -2 21 8 -3 15 -1 15 4
0 14 -27 13 -36 -1z"/>
<path d="M1909 2074 c-12 -15 -9 -46 6 -51 6 -2 11 7 10 19 0 13 5 23 10 23 6
0 9 -11 7 -23 -2 -13 1 -21 6 -18 5 3 12 19 14 35 4 24 1 29 -19 29 -12 0 -28
-6 -34 -14z"/>
<path d="M1987 2055 c4 -22 1 -41 -8 -52 -12 -14 -12 -16 1 -11 17 5 65 86 57
94 -3 3 -12 -7 -21 -23 l-15 -28 -1 28 c0 15 -4 27 -9 27 -6 0 -7 -16 -4 -35z"/>
<path d="M2080 2071 c-18 -34 16 -73 35 -41 4 6 1 9 -7 8 -7 -2 -13 6 -13 17
0 14 6 19 19 17 11 -2 15 1 11 7 -10 17 -33 13 -45 -8z"/>
<path d="M2152 2078 c-17 -17 -15 -47 3 -54 21 -9 47 15 43 40 -4 24 -28 32
-46 14z m27 -30 c-8 -14 -14 -17 -22 -9 -7 7 -6 15 3 25 17 22 32 9 19 -16z"/>
<path d="M2220 2075 c0 -5 -3 -21 -5 -35 -4 -19 -2 -18 10 8 18 38 38 34 29
-5 -5 -18 -3 -24 5 -19 6 4 11 20 11 36 0 26 -4 29 -25 28 -14 -1 -25 -7 -25
-13z"/>
<path d="M2295 2075 c-11 -36 -12 -55 -4 -55 5 0 9 8 9 19 0 10 7 24 15 31 13
11 15 8 16 -21 l1 -34 8 29 c5 16 7 33 4 37 -6 11 -46 7 -49 -6z"/>
<path d="M2372 2078 c-17 -17 -15 -48 4 -55 8 -3 21 0 27 7 10 10 8 12 -5 7
-10 -3 -20 -2 -23 3 -4 6 3 10 13 10 11 0 22 6 25 14 10 26 -19 36 -41 14z
m28 -8 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M2442 2078 c-17 -17 -15 -48 4 -55 8 -3 21 0 27 7 10 10 8 12 -5 7
-10 -3 -20 -2 -24 4 -9 15 15 39 33 32 11 -4 14 -2 9 5 -9 15 -29 15 -44 0z"/>
<path d="M2551 2071 c-19 -35 14 -73 35 -39 4 6 3 8 -4 4 -6 -3 -13 -2 -16 3
-3 5 4 11 15 14 12 3 19 12 17 19 -7 21 -36 21 -47 -1z m29 -1 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M2720 2069 c-16 -29 -5 -49 25 -49 18 0 25 5 25 19 0 11 3 26 6 35
10 25 -43 21 -56 -5z m37 -23 c-4 -9 -11 -16 -17 -16 -11 0 -14 33 -3 44 11
10 26 -11 20 -28z"/>
<path d="M2831 2058 c-1 -39 12 -51 23 -22 8 22 8 26 -3 19 -5 -3 -12 5 -14
17 -3 15 -5 9 -6 -14z"/>
<path d="M2864 2068 c3 -13 6 -29 6 -35 0 -22 16 -14 28 12 17 36 14 55 -3 25
-14 -25 -14 -25 -15 -2 0 12 -5 22 -11 22 -7 0 -9 -9 -5 -22z"/>
<path d="M2930 2075 c-17 -20 -8 -50 15 -50 24 -1 35 12 35 40 0 25 -33 31
-50 10z m26 -32 c-5 -12 -10 -13 -18 -5 -8 8 -8 15 3 28 15 18 25 4 15 -23z"/>
<path d="M3006 2048 c3 -27 0 -40 -13 -47 -10 -6 -13 -11 -7 -11 6 0 18 8 26
18 24 26 50 82 39 82 -5 0 -13 -9 -16 -20 -8 -25 -22 -26 -28 -2 -2 9 -3 0 -1
-20z"/>
<path d="M3070 2070 c0 -11 5 -20 10 -20 6 0 10 -5 10 -11 0 -6 -7 -9 -15 -5
-8 3 -15 1 -15 -4 0 -15 26 -12 40 5 10 12 9 16 -4 21 -9 3 -16 11 -16 16 0 6
7 7 16 4 8 -3 12 -2 9 4 -11 18 -35 11 -35 -10z"/>
<path d="M3150 2030 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3180 2030 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
